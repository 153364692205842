<template>
  <v-dialog
    v-model="open"
    width="600"
    persistent
    style="z-index:20001;"
  >
      <TaskEditForm
        v-if="task"
        :task="task"
        @close="close"
        @updated="onUpdated"
        @quiet-updated="onQuietUpdated"
      />
  </v-dialog>
</template>
<script>
import { mapMutations } from 'vuex'
import TaskEditForm from '@/components/tasks/TaskEditForm'
import TaskMixin from '@/mixins/TaskMixin'

export default {
  components: { TaskEditForm },
  mixins: [TaskMixin],
  props: {
    task: {
      type: [Boolean, Object],
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    task () {
      this.task ? this.show() : this.close()
    }
  },
  methods: {
    show () {
      this.open = true
    },
    close () {
      this.removeTask()
      this.open = false
    },
    onUpdated () {
      this.removeTask()
      this.$emit('updated')
    },
    onQuietUpdated (task) {
      this.updateTask(task)
      this.$forceUpdate()
    },
    ...mapMutations({
      updateTask: 'tasks/SET_TASK'
    })
  }
}
</script>
