<template>
  <div>
    <v-app-bar
      dense
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-bold">
        Modifier la tâche #{{ task.tsk_id }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text title="Voir sur la carte" :href="(task.area.are_lat) ? '/#/map/' + task.area.are_lat + ',' + task.area.are_lng : '/#/map/'"><v-icon>mdi-map-outline</v-icon></v-btn>
      <v-app-bar-nav-icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div>
      <v-sheet>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab class="text-uppercase">Informations</v-tab>
          <v-tab class="text-uppercase" v-if="permissions.staffmanagement.create">
            <v-badge inline :content="task.users.length" :value="task.users.length" v-if="permissions.staffmanagement.create">Agents</v-badge>
          </v-tab>
          <v-tab class="text-uppercase" v-if="permissions.time.create">
            <v-badge inline :content="getTotalTime(task)">Temps</v-badge>
          </v-tab>
          <v-tab class="text-uppercase" @change="tabFileActive = true">Fichiers</v-tab>
          <!-- <v-tab class="text-uppercase">Alertes</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <TaskFormDetails
              :task="editableTask"
              @save="saveTask"
              @close="close"
            />
          </v-tab-item>
          <v-tab-item v-if="permissions.staffmanagement.create">
            <TaskFormUsers
              :task="editableTask"
              @save="saveTask"
              @close="close"
            />
          </v-tab-item>
          <v-tab-item v-if="permissions.time.create">
            <TaskFormTime
              :task="editableTask"
              @save="saveTask"
              @close="close"
            />
          </v-tab-item>
          <v-tab-item>
            <!-- <TaskFormFiles :task="editableTask" @saved="$emit('updated')" @close="close" /> -->
            <DocumentTab  v-if="tabFileActive" :doc_xxx_id="task.tsk_id" :table_id="'tsk'"></DocumentTab>
          </v-tab-item>
          <!-- <v-tab-item>
            <EventTab v-if="tab === 4" :eve_xxx_id="task.tsk_id" :table_id="'tsk'" typfilter="ALT" title="Alertes" />
          </v-tab-item> -->
        </v-tabs-items>
      </v-sheet>
      <v-sheet v-if="taskCanBeAccepted(task) || taskCanBeFinished(task)" color="primary lighten-1" class="pa-4 text-center">
        <v-btn class="success" v-if="taskCanBeAccepted(task)" @click="convertTask">
          Accepter la tâche
        </v-btn>
        <v-btn class="warning" v-if="taskCanBeFinished(task)" @click="finishTask(getEditableTask(task))">
          Clôturer la tâche
        </v-btn>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TaskFormDetails from '@/components/tasks/TaskFormDetails'
import TaskFormUsers from '@/components/tasks/TaskFormUsers'
import TaskFormTime from '@/components/tasks/TaskFormTime'
//import TaskFormFiles from '@/components/tasks/TaskFormFiles'
import TaskMixin from '@/mixins/TaskMixin'
import DocumentTab from '@/components/documents/DocumentList.vue'
//import EventTab    from '@/components/events/EventList.vue'

export default {
  components: {
    TaskFormDetails,
    TaskFormUsers,
    TaskFormTime,
    //TaskFormFiles,
    DocumentTab,
    //EventTab
  },
  mixins: [TaskMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      tab: 0,
      tabFileActive: false,
      rights: {
        documentread: false
      }
    }
  },
  computed: {
    editableTask () {
      return this.getEditableTask(this.task)
    },
  },
  methods: {
    async saveTask (task) {
      try {
        this.loading = true
        const payload = this.getTaskPayload(task)
        const data = await this.updateTask(payload)
        this.$emit('updated', data)
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
      } finally {
        this.loading = false
      }
    },
    close () {
      this.$emit('close')
    },
    async finishTask () {
      try {
        this.loading = true
        const payload = this.getTaskPayload(this.editableTask)
        await this.updateTask(payload)
        //const payload = this.getTaskPayload(task)
        await this.closeTask(this.task.tsk_id)
        this.$emit('updated')
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
      } finally {
        this.loading = false
      }
    },
    async convertTask () {
      try {
        this.loading = true
        await this.acceptTask(this.task.tsk_id)
        this.$emit('updated')
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
      } finally {
        this.loading = false
      }
    },
    ...mapActions({
      updateTask: 'tasks/updateTask',
      closeTask: 'tasks/closeTask',
      acceptTask: 'tasks/acceptTask',
      canViewPage: 'user/canViewPage'
    })
  }
}
</script>
